import React from "react"

import ChevronRight from "@src/icons/chevron_right.svg"
import ChevronLeft from "@src/icons/chevron_left.svg"

import * as styles from "./CardsCarousel.module.scss"

const CardsCarousel = ({ title, cards, className }) => {
  const [carouselItems, setCarouselItems] = React.useState(cards)
  const totalCards = carouselItems.length
  const handlePrevClick = () => {
    setCarouselItems(prev => {
      return prev.map((_, i) => prev[(i + 1) % totalCards])
    })
  }
  const handleNextClick = () => {
    setCarouselItems(prev => {
      return prev.map((_, i) => prev[(i + 1 + totalCards) % totalCards])
    })
  }
  return (
    <div className={`${styles.cards} ${className}`}>
      <div className={styles.cardsHeading}>
        <h3>{title}</h3>
        {totalCards > 3 && (
          <div className={styles.cardsHeadingNav}>
            <button onClick={handlePrevClick}>
              <img src={ChevronLeft} />
            </button>
            <button onClick={handleNextClick}>
              <img src={ChevronRight} />
            </button>
          </div>
        )}
      </div>
      <div className={styles.cardsWrapper}>
        {carouselItems
          .map(card => (
            <div className={styles.cardsCard} id={card.title}>
              <div className={styles.cardsCardContent}>
                <figure className={styles.cardsCardImage}>
                  <img src={card.image} />
                </figure>
                <h2 className={styles.cardsCardTitle}>{card.title}</h2>
                <p className={styles.cardsCardDescription}>
                  {card.description}
                </p>
              </div>
              <div className={styles.cardsCardReadmore}>
                <a
                  href={card.readmore}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read More
                </a>
              </div>
            </div>
          ))
          .slice(0, 3)}
      </div>
    </div>
  )
}

export default CardsCarousel
