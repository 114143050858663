import React from "react"

import getAppUrl from "@src/utils/getAppUrl"
import Layout from "@src/components/Layout"
import Row from "../V2/components/Row"
import Button from "@src/components/Button"
import CardsCarousel from "../../components/CardsCarousel/CardsCarousel"
import CalloutWithDemoForm from "../../components/CalloutWithDemoForm/CalloutWithDemoForm"
import DotBG from "../V2/components/DotBG/DotBG"

import ShopifyLogo from "@src/icons/shopify_logo.png"
import Placeholder from "@src/icons/case-studies/placeholder.png"
import * as styles from "./RefundTools.module.scss"

const RefundTools = () => {
  const appUrl = getAppUrl.hook()

  return (
    <Layout
      title="Refund & Exchange Management Tool"
      description="A customisable tool to ensure centralisation of all the refund and exchange requests to ensure a seamless & efficient experience. "
      url="https://canonic.dev/refund-tools"
    >
      <section>
        <Row>
          <DotBG reverse />
          <div className={styles.uc}>
            <div className={styles.ucContent}>
              <h1>Refund & Exchange Management Tool</h1>
              <p>
                A customisable tool to ensure centralisation of all the refund
                and exchange requests to ensure a seamless & efficient
                experience.
              </p>
              <div className={styles.ucContentCtas}>
                <a href={"#get-a-demo"}>
                  <Button isPrimary isLean>
                    Get a Demo
                  </Button>
                </a>
                <a href={appUrl} target="_blank" rel="noopener noreferrer">
                  <Button className={styles.ucContentCtasOutline}>
                    Try for free
                  </Button>
                </a>
              </div>
            </div>
            <div className={styles.ucGrid}>
              <div className={styles.ucGridTitle}>
                <h4>Catergory</h4>
              </div>
              <div className={styles.ucGridHeading}>
                <h5>E-Commerce</h5>
                <h5>Marketplace</h5>
              </div>
              <div className={styles.ucGridTitle}>
                <h4>Use Case</h4>
              </div>
              <div className={styles.ucGridHeading}>
                <h5>Support Tool</h5>
                <h5>Admin Panels</h5>
              </div>
              <div className={styles.ucGridTitle}>
                <h4>Resources</h4>
              </div>
              <div className={styles.ucGridLogo}>
                <img src={ShopifyLogo} alt="Shopify Logo" />
              </div>
            </div>
          </div>
        </Row>
      </section>
      <Row>
        <figure className={styles.ucBigImg}>
          <img src={Placeholder} />
        </figure>
      </Row>
      <section>
        <Row>
          <DotBG reverse />
          <CardsCarousel
            title="Other things you can build"
            cards={[
              {
                image: Placeholder,
                title: "Multi-Platform Product Uploader",
                description:
                  "Showcase your products on multiple platforms, including Pinterest, Etsy and more. Maximise your reach and exposure directly from Shopify.",
                readmore: "/docs",
              },
              {
                image: Placeholder,
                title: "Out-of-Stock Order Management Solution",
                description:
                  "Monitor inventory levels & update the order status in real-time. Quickly respond to out-of-stock orders, keeping customers informed & reducing the risk of lost sales.",
                readmore: "/docs",
              },
              {
                image: Placeholder,
                title: "Partner Catalogue & Inventory Management",
                description:
                  "Centralise all the refund and exchange requests to ensure a seamless & efficient experience. Provide a Seamless experience for both customers and staff.",
                readmore: "/docs",
              },
            ]}
            className={styles.ucCards}
          />
        </Row>
      </section>
      <CalloutWithDemoForm originPage="Refund Tools" />
    </Layout>
  )
}

export default RefundTools
