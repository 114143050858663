// extracted by mini-css-extract-plugin
export var cardsCard = "CardsCarousel-module--cardsCard--3oqBJ";
export var cardsCardContent = "CardsCarousel-module--cardsCardContent--2zLTC";
export var cardsCardDescription = "CardsCarousel-module--cardsCardDescription--11H4a";
export var cardsCardImage = "CardsCarousel-module--cardsCardImage--39n3n";
export var cardsCardReadmore = "CardsCarousel-module--cardsCardReadmore--3b2WU";
export var cardsCardTitle = "CardsCarousel-module--cardsCardTitle--3ahpL";
export var cardsHeading = "CardsCarousel-module--cardsHeading--3OFQC";
export var cardsHeadingNav = "CardsCarousel-module--cardsHeadingNav--11GXz";
export var cardsWrapper = "CardsCarousel-module--cardsWrapper--g-HHX";
export var showIn = "CardsCarousel-module--show-in--20gPE";